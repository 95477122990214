.box-case-study {
  overflow: hidden;
}
.banner {
  height: 812px;
}
.banner .img-banner {
  position: absolute;
  height: 100%;
}
.banner-title h1 {
  font-size: 96px;
  line-height: 125px;
}
.client-case .container-inner {
  width: 1520px;
}
.client-case .brand {
  width: 50px;
  height: 50px;
}
.client-case .border-box {
  border-left: 1px solid #b89848;
}
.client-case .client-identify {
  padding-bottom: 20px;
  border-bottom: 1px solid #b89848;
}
.client-identify .box-indentify {
  gap: 10px;
}
.client-identify h6,
.client-description h6 {
  font-size: 24px;
  line-height: 32px;
}
.client-identify p {
  font-size: 20px;
  line-height: 26px;
}

.client-strategy {
  margin: 0 0 200px 0;
}
.strategy-objectif {
  padding: 0 0 50px 0;
  border-bottom: 1px solid #b89848;
}

.client-strategy .title {
  position: relative;
  font-size: 40px;
  line-height: 52px;
  margin: 0 0 50px 0;
}
.client-case .about-brand p,
.client-case .about-brand span,
.client-strategy .objective p,
.client-strategy .objective span,
.client-strategy .challange p,
.client-strategy .challange span,
.client-strategy .solution p,
.client-strategy .solution span {
  font-family: "DMSans-400", sans-serif !important;
  font-size: 20px !important;
  line-height: 26px !important;
}

.client-strategy h6::before {
  content: "";
  position: absolute;
  left: 0;
  border: 10px solid #b89848;
  height: 100%;
}
.strategy-objectif h6::before {
  left: 14px;
}
.strategy-objectif h6,
.strategy-objectif .objective {
  padding: 0 0 0 66px;
}
.client-case-project {
  position: relative;
}
.case-bg-left {
  position: absolute;
  top: 0;
  left: 0;
}
.case-bg-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.case .content .s-4052 {
  padding-left: 32px;
  border-left: 20px solid #b89848;
  height: 60px;
  margin-bottom: 54px;
}

@media (max-width: 1600px) {
  .client-case .container-inner {
    width: 1361px;
  }
  .banner-title h1 {
    font-size: 86px;
    line-height: 115px;
  }
  .client-strategy {
    margin: 0 0 160px 0;
  }
  .client-strategy .title {
    font-size: 38px;
    line-height: 48px;
    margin: 0 0 46px 0;
  }
}
@media (max-width: 1400px) {
  .client-case .container-inner {
    width: 1160px;
  }
  .banner-title h1 {
    font-size: 76px;
    line-height: 105px;
  }
  .client-identify h6,
  .client-description h6 {
    font-size: 22px;
    line-height: 30px;
  }
  .client-case .about-brand p,
  .client-case .about-brand span,
  .client-strategy .objective p,
  .client-strategy .objective span,
  .client-strategy .challange p,
  .client-strategy .challange span,
  .client-strategy .solution p,
  .client-strategy .solution span {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .client-identify p {
    font-size: 18px;
    line-height: 24px;
  }
  .client-case .brand {
    width: 48px;
    height: 48px;
  }
  .client-strategy {
    margin: 0 0 120px 0;
  }
  .client-strategy .title {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 42px 0;
  }
}
@media (max-width: 1200px) {
  .client-case .container-inner {
    width: 985px;
  }
  .banner-title h1 {
    font-size: 66px;
    line-height: 95px;
  }
  .client-case .brand {
    width: 46px;
    height: 46px;
  }
  .client-strategy .title {
    font-size: 34px;
    line-height: 44px;
    margin: 0 0 38px 0;
  }
  .client-strategy {
    margin: 0 0 100px 0;
  }
}
@media (max-width: 1024px) {
  .client-case .container-inner {
    width: 952px;
  }
  .banner-title h1 {
    font-size: 56px;
    line-height: 85px;
  }
  .client-identify h6,
  .client-description h6 {
    font-size: 20px;
    line-height: 28px;
  }
  .client-case .about-brand p,
  .client-case .about-brand span,
  .client-strategy .objective p,
  .client-strategy .objective span,
  .client-strategy .challange p,
  .client-strategy .challange span,
  .client-strategy .solution p,
  .client-strategy .solution span {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .client-identify p {
    font-size: 16px;
    line-height: 22px;
  }
  .client-case .brand {
    width: 44px;
    height: 44px;
  }
  .client-strategy {
    margin: 0 0 80px 0;
  }
  .client-strategy .title {
    font-size: 32px;
    line-height: 42px;
    margin: 0 0 34px 0;
  }
}

@media (max-width: 991px) {
  .client-case .container-inner {
    width: calc(100% - 28px);
  }
  .banner {
    height: calc(100vh + 50px);
  }
  .banner-title h1 {
    font-size: 46px;
    line-height: 85px;
  }
  .client-identify h6,
  .client-description h6 {
    font-size: 18px;
    line-height: 26px;
  }
  .client-strategy {
    margin: 0 0 60px 0;
  }

  .box-case-study .s-2431 {
    font-size: 16px;
    line-height: 20px;
  }
  .client-case .border-box {
    border-left: none;
  }
  .client-case .client-description {
    padding: 0 0 20px 0;
    border-bottom: 1px solid #b89848;
  }
  .client-identify .col-lg-3 {
    margin: 0 0 24px 0;
  }
  .client-identify .col-lg-3:last-child {
    margin: 0;
  }
  .client-strategy h6::before {
    border: 5px solid #b89848;
  }

  .strategy-objectif .objective {
    padding: 0 0 0 40px;
  }
  .client-strategy h6 {
    font-size: 20px;
    line-height: 26px;
    padding-left: 25px;
  }
  .strategy-objectif h6 {
    padding: 0 0 0 40px;
  }
  /* .client-strategy h6, */
  .client-strategy .challange,
  .client-strategy .solution {
    padding-left: 25px;
    margin: 0 0 50px 0;
  }
  .client-identify p {
    font-size: 14px;
    line-height: 18px;
  }
  .client-case .brand {
    width: 40px;
    height: 40px;
  }

  .client-strategy .title {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 30px 0;
  }
}

@media (max-width: 767px) {
  .banner-title h1 {
    font-size: 41px;
    line-height: 75px;
  }
  .client-identify h6,
  .client-description h6 {
    font-size: 16px;
    line-height: 20px;
  }
  .client-case .about-brand p,
  .client-case .about-brand span,
  .client-strategy .objective p,
  .client-strategy .objective span,
  .client-strategy .challange p,
  .client-strategy .challange span,
  .client-strategy .solution p,
  .client-strategy .solution span {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .client-case .brand {
    width: 34px;
    height: 34px;
  }
  .client-strategy .title {
    font-size: 28px;
    line-height: 36px;
    margin: 0 0 26px 0;
  }
  .client-strategy {
    margin: 0 0 40px 0;
  }
}
@media (max-width: 568px) {
  .banner-title h1 {
    font-size: 36px;
    line-height: 65px;
  }
  .client-case .brand {
    width: 30px;
    height: 30px;
  }
  .client-strategy {
    margin: 0 0 20px 0;
  }
  .client-strategy .title {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 22px 0;
  }
}
@media (max-width: 414px) {
  .banner-title h1 {
    font-size: 32px;
    line-height: 42px;
  }
  .client-case .brand {
    width: 24px;
    height: 24px;
  }
  .client-strategy {
    margin: 0;
  }
  .client-strategy .title {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 16px 0;
  }
}
