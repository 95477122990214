.card {
  gap: 20px;
  padding: 30px;
  height: 477px;
  border: none;
}
.about {
  overflow: hidden;
}
.banner-about {
  height: calc(100vh + 100px);
  min-height: 1080px;
  margin: 0 0 243px 0;
}
.banner-content {
  position: absolute;
  z-index: 3;
}
.box-inner {
  float: left;
  width: 1520px;
}

.about-text {
  float: left;
  width: 31.2%;
}
.about-text h1 {
  font-size: 64px;
  line-height: 83px;
}
.about-img {
  float: left;
  width: 68.8%;
}
.about-img .content-img {
  height: 700px;
}
.banner-about .box-img-left {
  position: relative;
  float: left;
  margin-left: 15%;
  width: 50%;
  height: 453px;
  background: rgba(255, 255, 255, 0.2);
}

.about-img .banner-left,
.about-img .banner-right {
  position: absolute;
  left: -5%;
  top: -5%;
  width: 100%;
  height: 100%;
}
.box-img-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 453px;
  background: rgba(255, 255, 255, 0.2);
}
.banner-img {
  height: 100%;
}

.banner-desc p,
.banner-desc span {
  font-family: "DMSans-400", sans-serif;
  font-size: 24px;
  line-height: 31px;
  color: #fff;
}
.wd-504 {
  width: 504px;
}
.container-story {
  width: 1520px;
  margin: 0 0 200px 0;
}

.box-story-desc {
  float: left;
  width: 65%;
  height: 655px;
  padding: 0 0 0 37px;
  overflow-y: scroll;
}
.cr-story-desc {
  padding: 25px 100px 25px 150px;
  min-height: 655px;
}
.story-desc-detail p,
.story-desc-detail span {
  font-family: "DMSans-400", sans-serif;
  font-size: 20px;
  line-height: 26px;
}
.banner-about .wd-504 {
  margin-right: 150px;
}
.about .box-image {
  float: left;
  width: 34.5%;
  max-width: 520px;
  height: 655px;
  background: rgba(184, 152, 72, 0.2);
}
.experience-inner {
  float: left;
  width: calc(33.3% - 50px);
}
.box-experience h1 {
  font-size: 100px;
  line-height: 130px;
}
.box-experience h3 {
  font-size: 48px;
  line-height: 62px;
}
.box-experience p {
  font-size: 24px;
  line-height: 31px;
}
.about .wd-781 {
  width: 781px;
  margin-left: 200px;
}
.about .content .wd-420 {
  width: 420px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  margin: 0px 25px;
}
.about .box-image img {
  position: absolute;
  top: -37px;
  left: 37px;
  height: 100%;
}
.leadership {
  margin: 0 0 200px 0;
}

.leadership-list .leadership-img {
  height: 500px;
}
.ld-position {
  position: absolute;
  bottom: 30px;
}
.s-100 {
  font-size: 100px;
  line-height: 130px;
}
@media (max-width: 1600px) {
  .box-inner {
    width: 1361px;
  }
  .banner-about {
    margin: 0 0 223px 0;
  }
  .about-text h1 {
    font-size: 54px;
    line-height: 73px;
  }
  .banner-desc p,
  .banner-desc span {
    font-size: 22px;
    line-height: 28px;
  }
  .container-story {
    width: 1361px;
    margin: 0 0 180px 0;
  }
  .cr-story-desc {
    padding: 25px 90px 25px 140px;
  }
  .experience-inner {
    float: left;
    width: calc(33.3% - 45px);
  }
  .card {
    padding: 26px;
  }
  .box-experience h1 {
    font-size: 95px;
    line-height: 120px;
  }
  .box-experience h3 {
    font-size: 44px;
    line-height: 58px;
  }
  .box-experience p {
    font-size: 22px;
    line-height: 28px;
  }
  .leadership {
    margin: 0 0 180px 0;
  }
  .leadership-list .leadership-img {
    height: 450px;
  }
}
@media (max-width: 1400px) {
  .box-inner {
    width: 1160px;
  }
  .banner-about {
    min-height: 800px;
    margin: 0 0 203px 0;
  }
  .about-img .content-img {
    height: 500px;
  }
  .about-text h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .banner-desc p,
  .banner-desc span {
    font-size: 20px;
    line-height: 26px;
  }
  .banner-about .box-img-left,
  .banner-about .box-img-right {
    height: 353px;
  }
  .leadership-list .leadership-img {
    height: 400px;
  }

  .experience-inner {
    float: left;
    width: calc(33.3% - 40px);
  }
  .card {
    padding: 22px;
    height: 427px;
  }
  .box-experience h1 {
    font-size: 85px;
    line-height: 120px;
  }
  .box-experience h3 {
    font-size: 40px;
    line-height: 54px;
  }
  .box-experience p {
    font-size: 20px;
    line-height: 24px;
  }
  .s-100 {
    font-size: 70px;
    line-height: 80px;
  }
  .s-4862 {
    font-size: 30px;
    line-height: 52px;
  }
  .s-2431 {
    font-size: 22px;
    line-height: 26px;
  }
  .container-story {
    width: 1160px;
    margin: 0 0 160px 0;
  }
  .cr-story-desc {
    padding: 25px 80px 25px 120px;
  }
  .story-desc-detail p,
  .story-desc-detail span {
    font-size: 18px;
    line-height: 24px;
  }
  .leadership {
    margin: 0 0 160px 0;
  }
}
@media (max-width: 1200px) {
  .box-inner {
    width: 985px;
  }
  .banner-about {
    margin: 0 0 183px 0;
  }
  .about-img .content-img {
    height: 440px;
  }
  .banner-about .box-img-left {
    margin-left: 10%;
  }
  .banner-about .box-img-left,
  .banner-about .box-img-right {
    height: 280px;
  }
  .about-text h1 {
    font-size: 34px;
    line-height: 44px;
  }
  .banner-desc p,
  .banner-desc span {
    font-size: 18px;
    line-height: 22px;
  }
  .container-story {
    width: 985px;
    margin: 0 0 140px 0;
  }

  .about .box-image,
  .box-story-desc {
    height: 550px;
  }
  .cr-story-desc {
    padding: 25px 70px 25px 100px;
  }
  .story-desc-detail p,
  .story-desc-detail span {
    font-size: 16px;
    line-height: 22px;
  }
  .experience-inner {
    width: calc(33.3% - 35px);
  }
  .card {
    padding: 20px;
    height: 377px;
  }
  .box-experience h1 {
    font-size: 75px;
    line-height: 110px;
  }
  .box-experience h3 {
    font-size: 36px;
    line-height: 50px;
  }
  .box-experience p {
    font-size: 18px;
    line-height: 22px;
  }
  .leadership {
    margin: 0 0 140px 0;
  }
  .leadership-list .leadership-img {
    height: 350px;
  }
}
@media (max-width: 1024px) {
  .box-inner {
    width: 952px;
  }
  .banner-about {
    margin: 0 0 163px 0;
  }
  .container-story {
    width: 952px;
    margin: 0 0 120px 0;
  }
  .about .box-image,
  .box-story-desc {
    padding: 0 0 0 30px;
    height: 500px;
  }
  .about .box-image img {
    top: -25px;
    left: 30px;
  }
  .cr-story-desc {
    padding: 25px 60px 25px 80px;
  }
  .experience-inner {
    width: calc(33.3% - 30px);
  }
  .card {
    gap: 18px;
    height: 327px;
  }
  .box-experience h1 {
    font-size: 65px;
    line-height: 100px;
  }
  .box-experience h3 {
    font-size: 32px;
    line-height: 42px;
  }
  .box-experience p {
    font-size: 16px;
    line-height: 20px;
  }
  .leadership {
    margin: 0 0 120px 0;
  }
  .leadership-list .leadership-img {
    height: 300px;
}
}
@media (max-width: 991px) {
  .box-inner {
    width: calc(100% - 28px);
  }
  .banner-about .box-img-left {
    margin-left: 10%;
  }
  .about-text {
    width: 35%;
  }
  .about-img {
    width: 65%;
  }
  .about-text h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .banner-desc p,
  .banner-desc span {
    font-size: 16px;
    line-height: 20px;
  }
  .about .box-image,
  .box-story-desc {
    height: 455px;
  }
  .box-experience {
    justify-content: center !important;
    gap: 20px;
  }
  .experience-inner {
    width: 100%;
  }
  .card {
    gap: 16px;
    height: 277px;
  }
  .box-experience h1 {
    font-size: 55px;
    line-height: 85px;
  }
  .box-experience h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .box-experience p {
    font-size: 16px;
    line-height: 20px;
  }
  .leadership-list .leadership-img {
    height: 300px;
  }
  .s-100 {
    font-size: 60px;
    line-height: 80px;
  }
  .s-4862 {
    font-size: 26px;
    line-height: 32px;
  }
  .s-2431 {
    font-size: 18px;
    line-height: 22px;
  }

  .box-content-inner {
    flex-wrap: wrap-reverse;
  }
  .about-text,
  .about-img {
    width: 100%;
  }
  .banner-about {
    min-height: 1080px;
    margin: 0 0 143px 0;
  }
  .banner-content {
    flex-wrap: wrap-reverse;
  }
  .about-img .box-img-left,
  .about-img .box-img-right {
    height: 352px;
  }
  .banner-content .content-text,
  .banner-content .content-img {
    width: calc(100% - 15px);
    margin: 40px 0;
  }
  .container-story {
    width: calc(100% - 28px);
    margin: 0 0 100px 0;
  }
  .about .box-image {
    width: 70%;
    height: 500px;
    margin: 0 0 50px 0;
  }
  .box-story-desc {
    width: 100%;
    height: auto;
    padding: 0;
    overflow-y: hidden;
  }
  .cr-story-desc {
    padding: 0;
    min-height: fit-content;
  }
  .leadership {
    margin: 0 0 100px 0;
  }
  .leadership h3 {
    font-size: 20px;
    line-height: 26px;
    text-align: center !important;
  }
}
@media (max-width: 767px) {
  .about-text h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .banner-desc p,
  .banner-desc span {
    font-size: 14px;
    line-height: 18px;
  }
  .banner-about {
    min-height: 950px;
    margin: 0 0 123px 0;
  }
  .about-img .content-img {
    height: 460px;
  }
  .banner-about .box-img-left,
  .banner-about .box-img-right {
    height: 310px;
  }
  .about .box-image {
    margin: 0 0 40px 0;
  }
  .about .box-image img {
    top: -20px;
    left: 20px;
  }
  .container-story {
    margin: 0 0 80px 0;
  }
  .story-desc-detail p,
  .story-desc-detail span {
    font-size: 14px;
    line-height: 18px;
  }
  .card {
    gap: 14px;
    height: auto;
  }
  .box-experience h1 {
    font-size: 50px;
    line-height: 75px;
  }
  .box-experience h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .box-experience p {
    font-size: 14px;
    line-height: 18px;
  }
  .leadership {
    margin: 0 0 80px 0;
  }
  .s-100 {
    font-size: 40px;
    line-height: 52px;
  }
  .s-4862 {
    font-size: 20px;
    line-height: 26px;
  }
  div .s-2431 {
    font-size: 14px;
    line-height: 18px;
  }
  .digital-wave .s-6483 {
    font-size: 25px;
    line-height: 35px;
  }
  .ld-position h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .ld-position p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 568px) {
  .banner-about {
    min-height: 950px;
    margin: 0 0 103px 0;
  }

  .about-text h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .about .box-image {
    height: 270px;
    margin: 0 0 30px 0;
  }
  .about-img .content-img {
    height: 330px;
  }
  .about .box-image img {
    top: -13px;
    left: 14px;
  }
  .banner-about .box-img-left,
  .banner-about .box-img-right {
    height: 220px;
  }
  .container-story {
    margin: 0 0 60px 0;
  }
  .card {
    gap: 12px;
  }
  .box-experience h1 {
    font-size: 45px;
    line-height: 65px;
  }
  .box-experience h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .digital-wave .s-6483 {
    font-size: 20px;
    line-height: 28px;
  }
  .leadership {
    margin: 0 0 60px 0;
  }
}
@media (max-width: 414px) {
  .about-text h1 {
    font-size: 20px;
    line-height: 26px;
  }
  .banner-about {
    min-height: 800px;
    margin: 0 0 63px 0;
  }
  .banner-content .content-text,
  .banner-content .content-img {
    width: calc(100% - 15px);
    margin: 25px 0;
  }
  .about-img .content-img {
    height: 235px;
  }
  .banner-about .box-img-left,
  .banner-about .box-img-right {
    height: 152px;
  }
  .container-story {
    margin: 0 0 50px 0;
  }
  .card {
    gap: 10px;
  }
  .box-experience h1 {
    font-size: 40px;
    line-height: 53px;
  }
  .box-experience h3 {
    font-size: 20px;
    line-height: 26px;
  }

  div .s-1823 {
    font-size: 14px;
    line-height: 18px;
  }
  .leadership {
    margin: 0 0 50px 0;
  }
  .leadership-list .leadership-img {
    height: 168px;
  }
}
