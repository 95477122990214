.banner-erp {
  height: calc(100vh + 100px);
  min-height: 1080px;
}

.banner-content {
  position: absolute;
  z-index: 3;
}
.box-inner {
  float: left;
  width: 1520px;
}
.erp-text {
  float: left;
  width: 30%;
}
.erp-img {
  float: left;
  width: 70%;
}
.content-img {
  height: 540px;
}
.box-img-left {
  position: relative;
  float: left;
  margin-left: 15%;
  width: 50%;
     height: 453px;
  background: rgba(255, 255, 255, 0.2);
}
.box-img-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
     height: 453px;
  background: rgba(255, 255, 255, 0.2);
}
.erp-img .banner-left,
.erp-img .banner-right {
  position: absolute;
  left: -5%;
  top: -5%;
  width: 100%;
  height: 100%;
}
.erp .strategy-objectif {
  padding: 0 0 50px 0;
  border-bottom: none;
}
.product-video div div {
  display: flex;
  justify-content: center;
  height: 800px !important;
}
.product-video video {
  width: 1600px !important;
  height: 800px !important;
}
@media (max-width: 1600px) {
  .box-inner {
    width: 1361px;
    min-width: unset;
  }
  .product-video div div {
    width: 100% !important;
    height: 1200px !important;
  }
  .product-video video {
    width: 1366px !important;
    height: 1200px !important;
  }
}

@media (max-width: 1400px) {
  .box-inner {
    width: 1160px;
  }
  .product-video div div {
    height: 900px !important;
  }
  .product-video video {
    width: 1024px !important;
    height: 900px !important;
  }
}
@media (max-width: 1200px) {
  .box-inner {
    width: 985px;
  }
}
@media (max-width: 1024px) {
  .box-inner {
    width: 952px;
  }
  .product-video div div {
    height: 850px !important;
  }
  .product-video video {
    width: 991px !important;
    height: 850px !important;
  }
}
@media (max-width: 991px) {
  .box-inner {
    width: calc(100% - 28px);
  }
  .box-content-inner {
    flex-wrap: wrap-reverse;
  }
  .box-img-left {
    margin-left: 10%;
}
  .content-img {
    height: 420px;
  }
  .erp-text,
  .erp-img {
    width: 100%;
  }
  .box-img-left,
  .box-img-right {
    height: 260px;
  }
  .erp .strategy-objectif {
    padding: 0 0 50px 14px;
  }
  .erp .strategy-objectif h6 {
    padding: 0 0 0 25px;
  }
  .erp .strategy-objectif h6::before {
    left: 0px;
  }
  .product-video div div {
    height: 600px !important;
  }
  .product-video video {
    width: 767px !important;
    height: 600px !important;
  }
}
@media (max-width: 767px) {
  .banner-erp {
    min-height: 950px;
  }
  .content-img {
    height: 310px;
  }
  .box-img-left,
  .box-img-right {
    height: 200px;
  }

  .product-video div div {
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 500px !important;
  }
  .product-video video {
    width: 568px !important;
    height: 500px !important;
  }
}
@media (max-width: 568px) {
  .banner-erp {
    min-height: 830px;
  }
  .content-img {
    height: 230px;
  }
  .box-img-left,
  .box-img-right {
    height: 150px;
  }

  .product-video div div {
    height: 350px !important;
  }
  .product-video video {
    width: 414px !important;
    height: 350px !important;
  }
}
@media (max-width: 414px) {
  .banner-erp {
    min-height: 830px;
  }
  .content-img {
    height: 155px;
  }
  .box-img-left,
  .box-img-right {
    height: 150px;
  }
  .product-video div div {
    height: 300px !important;
  }
  .product-video video {
    width: 375px !important;
    height: 300px !important;
  }
}
