.z-2 {
  z-index: 2;
}

.z-5 {
  z-index: 5;
}

.z-10 {
  z-index: 10;
}

/* * TEXT COLOR & BACKGROUND START */
.c-ff {
  color: #fff;
}

.c-00 {
  color: #000;
}

.c-82 {
  color: #828282;
}

.c-52 {
  color: #525252;
}

.c-89 {
  color: #b89848;
}

.c-6f {
  color: #6f6f6f;
}

.bg-ff {
  background-color: #fff !important;
}

.bg-ef {
  background-color: #efefef !important;
}

.bg-f8 {
  background-color: #f8f8f8 !important;
}

.bg-89 {
  background-color: #b89848 !important;
}

.bg-f3 {
  background-color: #f3f3f3 !important;
}

.bg-f0 {
  background-color: #f0ddac !important;
}

.bg-255 {
  background: rgba(255, 255, 255, 0.5) !important;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-40 {
  padding: 40px;
}

.py-50 {
  padding: 50px 0;
}

.py-200 {
  padding: 200px 0;
}

/* TEXT COLOR & BACKGROUND END  */
.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-20 {
  border-radius: 20px;
}

.br-30 {
  border-radius: 30px;
}

.br-60 {
  border-radius: 60px;
}

.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}
.min-h-100-vh {
  min-height: 100vh;
}
a.no {
  text-decoration: none;
}

.s-1418 {
  font-size: 14px;
  line-height: 18px;
}

.s-1621 {
  font-size: 16px;
  line-height: 21px;
}

.s-1823 {
  font-size: 18px;
  line-height: 23px;
}

.s-2431 {
  font-size: 24px;
  line-height: 31px;
}

.s-3242 {
  font-size: 32px;
  line-height: 42px;
}

.s-3647 {
  font-size: 36px;
  line-height: 47px;
}
.s-4052 {
  font-size: 40px;
  line-height: 52px;
}

.s-4862 {
  font-size: 48px;
  line-height: 62px;
}

.s-5065 {
  font-size: 50px;
  line-height: 65px;
}

.s-6483 {
  font-size: 64px;
  line-height: 83px;
}

.s-96 {
  font-size: 96px;
  line-height: 125px;
}

.s-100 {
  font-size: 100px;
  line-height: 163px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-54 {
  margin-bottom: 54px;
}

.mb-60 {
  margin-bottom: 60px;
}
.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mt-40 {
  margin-top: 40px;
}

.ms-40 {
  margin-left: 40px;
}

.my-50 {
  margin: 50px 0;
}

.my-100 {
  margin: 100px 0;
}

.my-200 {
  margin: 200px 0;
}

.pl-52 {
  padding-left: 52px;
}

.relative {
  position: relative;
}

.container-inner {
  float: left;
  width: 80%;
}

.card {
  padding: 40px;
  border-radius: 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.img-cover {
  object-fit: cover;
  object-position: center;
}

.img-contain {
  object-fit: contain;
  object-position: center;
}

.width-100 {
  float: left;
  width: 100%;
}

.width-70 {
  float: left;
  width: 70%;
}

.width-60 {
  float: left;
  width: 60%;
}

.width-50 {
  float: left;
  width: 50%;
}

.width-40 {
  float: left;
  width: 40%;
}

.width-30 {
  float: left;
  width: 30%;
}

/* FOOTER START  */
.box-footer {
  padding: 95px 0px;
  gap: 27px;
}
.box-footer-icon {
  gap: 20px;
}
.box-footer .justify-content-center img {
  width: 30px;
  height: 30px;
}

.decotarion-none {
  text-decoration: none;
}

/* FOOTER END  */

/*
 *  STYLE 4
 */

.style-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.style-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.style-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b89848;
  border: 2px solid #b89848;
}

.wrapper {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
}
.box-wrapper {
  float: left;
  width: 100%;
  max-width: 1920px;
  overflow-x: hidden;
}
/* Style Header start */
.box-side .MuiPaper-root {
  height: max-content;
  padding: 0 0 30px 0;
  border-radius: 30px 0px 0px 30px;
}
.header {
  position: fixed;
  top: 0px;
  z-index: 99;
  background-color: transparent;
  transition: 0.4s;
}

.header.shd {
  background: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}

.header .logo {
  height: 23px;
  width: 166px;
  object-fit: contain;
}

.header .menu img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  margin-left: 14px;
  transition: 0.3s;
}

.header .justify-content-between {
  width: calc(100% - 94px);
  margin: 30px auto;
}

.header-list {
  height: 73px;
  margin: auto 0px auto auto;
  display: flex;
  align-items: center;
  padding-right: 6px;
  text-transform: capitalize;
  text-decoration: none;
}

.close-header {
  margin: 20px 20px 30px auto;
  display: block;
}

.header-media {
  margin-top: 30px;
  padding-right: 20px;
}

.header-media img {
  margin-left: 20px;
}

/* Style Header start */
/* style mouse scroll to more start */
.scroll-downs {
  position: absolute;
  bottom: calc(10% + 40px);
  left: 3%;
  margin: auto;
  width: 17.5px;
  height: 25px;
  z-index: 10;
}
.mousey {
  position: relative;
  width: 3px;
  padding: 0px 5px;
  height: 25px;
  border: 2px solid #fff;
  border-radius: 25px;
  box-sizing: content-box;
}
.scroll-downs p {
  position: absolute;
  bottom: -100px;
  font-family: "DMSans-400";
  font-size: 20px;
  line-height: 26px;
  width: 139px;
  text-align: start;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
.scroller-arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -70px;
  left: 0;
  margin: auto;
  content: url("../assets/icons/arrorw-down.png");
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
/* style mouse scroll to more end */
.digital-wave {
  height: 800px;
}
.digital-wave .bg-wave {
  position: absolute;
  height: 100%;
}
.box-contact {
  margin-top: 63px;
  padding: 14px;
}
.contact-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: #b898486b;
  animation: animContact 3s infinite;
}
@keyframes animContact {
  50% {
    opacity: 0;
  }
}
.digital-wave button {
  padding: 20px 40px;
  border: 1px solid #b89848;
}
/*
  *  STYLE 5
  */
.scroll-to-top {
  z-index: 99 !important;
  right: 60px !important;
  bottom: 150px !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 100% !important;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-chat-wa {
  position: fixed;
  z-index: 10;
  right: 50px;
  bottom: 50px;
  background-color: #52ce5f;
  border-radius: 50px;
  padding: 15px;
  transition: 0.3s;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  animation: animWa 3s ease infinite;
}
@keyframes animWa {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
.box-chat-wa:hover {
  opacity: 0.9;
}
.box-chat-wa .icon-wa {
  width: 40px;
  height: 40px;
}
.box-text {
  transition: 0.5s;
  display: none;
}
.box-wa:hover + .box-text {
  transition: 0.5s;
  display: block;
}
@media (max-width: 1600px) {
  .scroll-to-top {
    bottom: 140px !important;
  }
}
@media (max-width: 1400px) {
}
@media (max-width: 1366px) {
  .s-100 {
    font-size: 60px;
    line-height: 140px;
  }
  .digital-wave .s-6483 {
    font-size: 50px;
    line-height: 53px;
  }
  .digital-wave {
    height: 712px;
  }
}
@media (max-width: 1200px) {
  .scroll-to-top {
    right: 62.5px !important;
    width: 45px !important;
    height: 45px !important;
  }
  .scroll-to-top svg {
    width: 16px !important;
    height: 20px !important;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
  .scroll-to-top {
    right: 26px !important;
    bottom: 125px !important;
    width: 40px !important;
    height: 40px !important;
  }
  .scroll-to-top svg {
    width: 14px !important;
    height: 18px !important;
  }
  .box-chat-wa {
    right: 20px;
  }
  .s-100 {
    font-size: 40px;
    line-height: 52px;
  }

  .s-1418 {
    font-size: 12px;
    line-height: 16px;
  }

  div .s-1823 {
    font-size: 14px;
    line-height: 20px;
  }

  div .s-2431 {
    font-size: 14px;
    line-height: 14px;
  }

  div .s-3242 {
    font-size: 22px;
    line-height: 32px;
  }

  .s-96 {
    font-size: 32px;
    line-height: 42px;
  }

  div h3,
  div .s-3647 {
    font-size: 20px;
    line-height: 26px;
  }

  div .mb-100 {
    margin-bottom: 50px;
  }

  div .mb-200 {
    margin-bottom: 100px;
  }

  div h4 {
    font-size: 22px;
    line-height: 37px;
  }

  div .s-5065 {
    font-size: 28px;
    line-height: 40px;
  }

  div .s-2026 {
    font-size: 16px;
    line-height: 18px;
  }

  div .s-6483 {
    font-size: 30px;
    line-height: 38px;
  }

  div .header-list {
    height: 46px;
  }

  div .header-media img {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  div .mb-40 {
    margin-bottom: 16px;
  }

  div .mb-50 {
    margin-bottom: 30px;
  }

  .container-inner {
    float: left;
    width: calc(100% - 28px);
  }

  .scroll-downs {
    left: 0;
    right: 0;
    bottom: calc(15% + 40px);
    display: flex;
    justify-content: center;
  }
  .mousey {
    border: 0px solid transparent;
  }
  .scroller {
    width: 0px;
  }
  .scroll-downs p {
    bottom: -75px;
    font-size: 14px;
    line-height: 18px;
    width: max-content;
    text-align: center;
  }
  /* style header start */
  div .header .logo {
    height: 16px;
    width: 110px;
  }

  div .header .menu img {
    height: 28px;
    width: 28px;
  }

  div .header .justify-content-between {
    width: calc(100% - 48px);
    margin: 20px auto;
  }

  /* style header end */
  /* style digital wave */

  .digital-wave .s-6483 {
    font-size: 40px;
    line-height: 55px;
  }
  .box-contact {
    margin-top: 33px;
    padding: 14px;
  }
  .digital-wave button {
    padding: 12px 25px;
  }
  /* style digital wave */

  /* Style Footer start */
  .box-footer {
    padding: 30px 0px;
    gap: 14px;
  }
  .box-footer-icon {
    gap: 10px;
  }

  .box-footer .justify-content-center img {
    width: 20px;
    height: 20px;
  }

  /* Style Footer start */
  .card {
    padding: 20px;
  }
  .box-chat-wa .icon-wa {
    width: 24px;
    height: 24px;
  }
  .main-text-inner {
    width: 90%;
}
}
@media (max-width: 767px) {
  div .s-2026 {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 568px) {
  div .s-2026 {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 414px) {
  .box-contact {
    padding: 14px;
  }
  .digital-wave button {
    padding: 6.5px 23px;
  }
}
