@font-face {
    font-family:DMSans-700 ;
    src: url("./DMSans/DMSans-Black.ttf");
}
@font-face {
    font-family:DMSans-600 ;
    src: url("./DMSans/DMSans_36pt-SemiBold.ttf");
}
@font-face {
    font-family:DMSans-500 ;
    src: url("./DMSans/DMSans-Medium.ttf");
}
@font-face {
    font-family:DMSans-400 ;
    src: url("./DMSans/DMSans-Regular.ttf");
}
@font-face {
    font-family:DMSans-300 ;
    src: url("./DMSans/DMSans-Light.ttf");
}
@font-face {
    font-family:Arlon ;
    src: url("./Arlon/ArlonSemiBold-DEMO.otf");
}

.DMSans-700{
    font-family: DMSans-700, sans-serif;
    font-weight: 700;
}
.DMSans-600{
    font-family: DMSans-600, sans-serif;
    font-weight: 600;
}
.DMSans-500{
    font-family: DMSans-500, sans-serif;
    font-weight: 500;
}
.DMSans-400{
    font-family: DMSans-400, sans-serif;
    font-weight: 400;
}
.DMSans-300{
    font-family: DMSans-300, sans-serif;
    font-weight: 300;
}
.Arlon{
    font-family: Arlon, sans-serif;
    font-weight: 600;
}
h1,h2,h3,h4,h5,h6,p, span, div{
    margin-bottom: 0px;
}
