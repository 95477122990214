.homepage {
  overflow: hidden;
}
.box-main-banner {
  height: 100vh;
}

/* OUR JOURNEY START  */
.main-video {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.homepage .main-video div {
  float: left;
  width: 100% !important;
  height: 100vh !important;
}
.homepage .main-video video {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
}

.box-our-journey .bg-journey {
  position: absolute;
  height: 100%;
}
.main-text-inner {
  float: left;
  width: 80%;
}
.main-text h1 {
  font-family: "DMSans-700", sans-serif;
  font-size: 70px;
  line-height: 100px;
  color: #fff;
}
.box-our-journey .container-inner {
  width: 1520px;
}
.box-journey {
  height: 1080px;
}
.journey-desc {
  float: left;
  width: 85%;
  margin: 368px 0 230px 0;
}
.paginate-list {
  padding: 368px 0 230px 0;
}
.paginate-list .times {
  gap: 14px;
}
.journey-title {
  position: absolute;
  height: 63px;
  margin: 230px 0 75px 0;
}
.journey-title h3 {
  position: absolute;
  font-size: 48px;
  line-height: 65px;
}
.journey-desc .card-inner {
  padding: 20px;
}
.journey-desc .journey-description p,
.journey-desc .journey-description p span {
  font-size: 18px !important;
  line-height: 24px !important;
  background-color: transparent !important;
}

.journey-desc .card {
  padding: 40px;
  height: 294px;
}
.journey-desc .journey-date {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 40px;
}
.box-journey h6 {
  font-size: 24px;
  line-height: 31px;
  text-transform: uppercase;
  margin-bottom: 20px;
  height: 62px;
}

.journey-paginate {
  float: left;
  width: 15%;
  justify-content: flex-end;
  position: relative;
}
.paginate-list::before {
  content: "";
  position: absolute;
  left: -51%;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #b89848;
}
.btn-year-list {
  width: 100px;
  height: 51px;
  border-radius: 40px;
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  transition: 0.3s;
  font-size: 18px;
  line-height: 23px;
}
.btn-year-list.btn:hover,
.btn-year-list.btn:active {
  background-color: #fff;
  border-color: #fff;
  color: #b89848;
}
.btn-year-list.active {
  background-color: #fff;
  border-color: #fff;
  color: #b89848;
  transition: 0.3s;
}
.btn-year-list::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: transparent;
  left: -57%;
  transition: 0.3s;
}
.btn-year-list.active::before {
  background-color: #fff;
  transition: 0.3s;
}
.times img {
  height: 40px;
  width: 40px;
}

/* OUR JOURNEY END  */

/* OUR CLIENTS START  */
/* style client study start */
.client-inner {
  padding: 25px;
}
/* Custom container for the dots */
.client-case-study {
  width: calc(100% - 70px);
}
.client-case-study .slick-dots {
  top: 45%;
  right: -25%;
  height: 4%;
  width: 45%;
  transform: rotate(90deg);
}

/* Style for individual dots */
.client-case-study .slick-dots li {
  margin: 5px 0; /* Add spacing between dots */
  list-style: none;
}

.client-case-study .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  border: none;
  cursor: pointer;
}

.client-case-study .slick-dots li.slick-active button {
  background-color: black; /* Highlight active dot */
}
.client-card {
  height: 812px;
}
.client-card .img-client-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.client-content {
  justify-content: space-between;
}
.client-desc {
  float: left;
  width: 45%;
  max-width: 475px;
  height: 355px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(25px);
  border-radius: 25px;
  padding: 30px;
  box-sizing: border-box;
}
.client-desc p {
  font-family: "DMSans-400", sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
}
.img-client-brand {
  height: 55px;
  margin: 0 0 30px 0;
}
.client-ss {
  width: 50%;
  margin: 0 40px 60px 0;
}
.client-desc-mobile {
  display: none;
}
/* //style client study end */
.clients-box {
  padding: 150px 0px;
}
.clients-box h4 {
  font-size: 36px;
  line-height: 37px;
}
.clients-box h3 {
  font-size: 48px;
  line-height: 65px;
  margin: 100px 0;
}

.bg-slide img {
  width: 602px;
  height: 357px;
}
.client-list {
  overflow: hidden;
  margin: 200px 0;
}
.clients-card .icon {
  height: 55px;
  width: 55px;
  object-fit: contain;
}
.client-testimony {
  float: left;
  width: 1600px;
}
.client-testimony .card {
  padding: 24px;
  margin-bottom: 50px;
  height: 300px;
}
.client-testimony .slick-current {
  transform: scale(1.08);
  transition: 0.3s;
}
.clients-card h6 {
  font-size: 40px;
  line-height: 52px;
  margin: 30px auto 10px 0px;
}
.clients-box .card-item {
  height: 300px;
  padding: 30px;
  border-radius: 20px;
  box-sizing: border-box;
}
.homepage .shd-40 {
  border-radius: 10px;
}
.shd-40 {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}
.clients-box .small-icon img {
  height: 30px;
  width: 30px;
}
.clients-box .client-title {
  font-size: 32px;
  line-height: 42px;
  text-align: start;
}
.clients-box .sub-title {
  font-size: 24px;
  line-height: 32px;
  text-align: start;
  margin: 20px auto 20px 0px;
}
.client-testimony .testimony-desc p {
  font-family: "DMSans-400", sans-serif;
  font-size: 16px;
  line-height: 21px;
}
.clients-box .slick-dots li.slick-active button {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #b89848;
}
.clients-box .slick-dots li {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 100%;
}
.clients-box .slick-dots li button:before {
  opacity: 1;
  content: none;
  color: black;
}
.clients-box .slick-dots li button {
  background-color: #efefef;
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
.client-testimony .slick-prev,
.client-testimony .slick-next,
.software-option .slick-prev .software-option .slick-next {
  width: 30px;
  height: 30px;
}
.client-testimony .slick-prev:before,
.software-option .slick-prev:before {
  content: url(../assets/images/left1.png);
}
.client-testimony .slick-next:before,
.software-option .slick-next:before {
  content: url(../assets/images/right1.png);
}
/* OUR CLIENTS END  */

/* SOFTWARE SOLUTIONS START  */
.switch {
  max-width: fit-content;
  margin: 0 0 100px;
}
.switch,
.switch p {
  border-radius: 50px;
}
.switch p {
  font-size: 24px;
  line-height: 31px;
  padding: 20px 30px;
  transition: 0.3s;
}
.software-option {
  float: left;
  width: 1228px;
  margin: 50px 0 150px 0;
}
.software-solution h3 {
  font-size: 48px;
  line-height: 65px;
}
.software-option .card-outer {
  padding: 30px;
}
.software-option .card {
  padding: 34px 30px;
  height: 460px;
}
.software-option img {
  max-width: 280px;
}
.ss-detail .nav-back {
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}
.ss-detail .nav-back h6 {
  width: fit-content;
}
.software-utilize {
  position: relative;
  height: 1080px;
  overflow: hidden;
}
.uss-bg-top {
  position: absolute;
  top: 100px;
  left: 0;
}
.uss-bg-bottom {
  position: absolute;
  bottom: 100px;
  right: 0;
}
.stack img {
  width: 150px !important;
  height: 100px;
}
button.view {
  padding: 14px 30px;
  outline: none;
  border: 1px solid var(--Gold, #b89848);
}

.technology-stack {
  padding: 200px 0;
  overflow: hidden;
}
.technology-stack h6 {
  font-size: 36px;
  line-height: 47px;
  margin: 0 0 100px 0;
}
/* SOFTWARE SOLUTIONS END  */

/* ARTICLES START  */
.box-articles {
  margin: 100px 0 200px 0;
}
.contant-article {
  float: left;
  width: 1503px;
  margin: 0 0 60px 0;
}

.article-short {
  float: left;
  width: 27%;
  position: relative;
  padding: 0 24px 0 0;
}
.article-short h4 {
  font-family: "DMSans-700", sans-serif;
  font-size: 50px;
  line-height: 65px;
}
.article-short .description {
  font-size: 18px;
  line-height: 23px;
}
.box-link-view {
  margin: 40px 0 0 0;
}
.article-short .no {
  font-size: 18px;
  line-height: 23px;
}
.article-list {
  float: left;
  width: 73%;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-160 {
  margin-top: 160px;
}
.card-article {
  padding: 20px;
}
.box-articles .width-30 {
  padding-top: 14px;
}
.news-box {
  position: relative;
  border-radius: 10px 10px 0px 0px;
}
.news-box img {
  height: 253px;
  border-radius: 10px 10px 0px 0px;
}
.news-box p {
  position: absolute;
  top: 14px;
  left: 14px;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  width: fit-content;
  font-size: 18px;
  line-height: 23px;
}

.articles .text-box {
  height: 316px;
  padding: 20px;
}

.text-box h3 {
  font-size: 20px;
  line-height: 26px;
  margin: 30px 0 20px 0px;
  height: 52px;
}
.text-box .description {
  font-size: 18px;
  line-height: 23px;
}
.box-articles .slick-prev:before,
.box-articles .slick-next:before {
  content: none;
}
.box-articles .slick-arrow {
  position: absolute;
  top: unset;
  bottom: -85px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #b89848;
}
.box-articles .slick-prev:hover,
.box-articles .slick-prev:focus,
.box-articles .slick-next:hover,
.box-articles .slick-next:focus {
  color: transparent;
  outline: #b89848;
  background: #b89848;
}
.box-articles .slick-arrow:hover path,
.box-articles .slick-prev:focus path,
.box-articles .slick-next:hover path,
.box-articles .slick-next:focus path {
  fill: #fff !important;
}
.box-articles .slick-prev {
  right: 80px;
  left: unset;
}
.box-articles .slick-prev svg {
  transform: rotate(180deg);
}
.box-articles .slick-next {
  right: 10px;
}

/* ARTICLES END  */

/* CAREER START  */
.career-position {
  float: left;
  width: 23%;
  flex-direction: column;
  gap: 50px;
  padding: 0 15px 0 0;
}
.career-description {
  float: left;
  width: 77%;
  padding: 0 0 0 30px;
  border-left: 1px solid #b89848;
}
.career-position .no {
  font-family: "DMSans-700", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: #828282;
}
.career-position .c-89 {
  color: #b89848 !important;
}
.box-career {
  margin: 0 0 200px 0;
}
.box-career .container-inner {
  border-top: 1px solid #b89848;
  padding-top: 130px;
  width: 1520px;
}
.box-career h3 {
  font-size: 50px;
  line-height: 65px;
  margin: 0 0 100px 0;
}
.box-career h5 {
  font-size: 32px;
  line-height: 42px;
  margin: 0 0 50px 0;
}
.career-division {
  margin: 0 0 100px 0;
  gap: 50px;
}
.text-start.link a {
  padding: 0px 10px 10px 10px;
  border-bottom: 2px solid transparent;
  height: 51px;
  font-size: 24px;
  line-height: 31px;
}
.text-start.link a.c-89 {
  border-color: #b89848;
}
.aside a {
  margin-bottom: 50px;
}
.career-description ul li,
.career-description span,
.career-description p {
  text-align: start;
  font-size: 20px;
  line-height: 26px;
}
.description-point {
  margin: 0 0 50px 0;
}

.box-career .content {
  width: 1207px;
  padding: 0px 30px;
  border-left: 1px solid var(--Gold, #b89848);
}
.box-career button {
  border: 1px solid var(--Gold, #b89848);
  background: #fff;
  width: fit-content;
  padding: 14px 20px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
}
/* CAREER END  */

/* OFFICE LOCATION START  */
.box-office-location {
  margin: 0 0 200px 0;
}
.office-location {
  float: left;
  width: 1520px;
}
.address-location {
  float: left;
  width: 44%;
  max-width: 660px;
  gap: 50px;
}
.address-location h6 {
  font-size: 32px;
  line-height: 42px;
}
.address-location P {
  font-size: 24px;
  line-height: 31px;
}
.office-location h3 {
  font-size: 50px;
  line-height: 65px;
  margin: 0 0 100px 0;
}
.address-item {
  gap: 40px;
}
.office-location .icon {
  width: 40px;
  height: 40px;
}
.maps {
  float: left;
  width: 54%;
  max-width: 810px;
  height: 594px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}
.office-location iframe {
  float: left;
  width: 100% !important;
  height: 100%;
}
/* OFFICE LOCATION END  */
@media (max-width: 1600px) {
  .main-text h1 {
    font-size: 55px;
    line-height: 70px;
  }
  .box-our-journey .container-inner {
    width: 1361px;
  }
  .journey-title h3,
  .software-solution h3 {
    font-size: 44px;
    line-height: 58px;
  }
  .journey-desc .journey-date {
    font-size: 16px;
    line-height: 20px;
  }
  .box-journey h6 {
    font-size: 22px;
    line-height: 29px;
    height: 58px;
  }
  .journey-desc .journey-description p,
  .journey-desc .journey-description p span {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .software-option {
    margin: 50px 0 140px 0;
  }
  .journey-desc .card {
    padding: 30px;
  }
  .clients-box h4 {
    font-size: 34px;
    line-height: 35px;
  }
  .clients-box h3 {
    font-size: 44px;
    line-height: 58px;
    margin: 80px 0 87px 0;
  }
  .clients-box .client-title {
    font-size: 30px;
    line-height: 38px;
  }
  .clients-box .sub-title {
    font-size: 22px;
    line-height: 30px;
  }
  .client-testimony {
    width: 1400px;
  }
  .client-card {
    height: 712px;
  }
  .client-list {
    margin: 180px 0;
  }
  .client-desc .s-4052 {
    font-size: 38px;
    line-height: 48px;
  }
  .client-desc .mt-40 {
    margin-top: 30px;
  }
  .technology-stack {
    padding: 180px 0;
  }
  .technology-stack h6 {
    font-size: 32px;
    line-height: 42px;
    margin: 0 0 90px 0;
  }
  .box-articles {
    margin: 90px 0 180px 0;
  }
  .contant-article {
    width: 1360px;
  }
  .article-short h4 {
    font-size: 46px;
    line-height: 60px;
  }
  .card-article {
    padding: 16px;
  }
  .articles .text-box {
    height: 300px;
  }
  .text-box h3 {
    font-size: 18px;
    line-height: 24px;
    margin: 28px 0 18px 0px;
    height: 48px;
  }
  .text-box .description {
    font-size: 16px;
    line-height: 21px;
  }
  .box-office-location {
    margin: 0 0 180px 0;
  }
  .box-career {
    margin: 0 0 180px 0;
  }
  .box-career .container-inner {
    padding-top: 110px;
    width: 1361px;
  }
  .box-career h3 {
    font-size: 46px;
    line-height: 55px;
    margin: 0 0 90px 0;
  }
  .box-career h5 {
    font-size: 30px;
    line-height: 38px;
    margin: 0px 0 45px 0;
  }
  .career-division {
    margin: 0 0 90px 0;
    gap: 40px;
  }
  .text-start.link a {
    height: 48px;
    font-size: 22px;
    line-height: 28px;
  }
  .description-point {
    margin: 0 0 45px 0;
  }
  .career-position {
    gap: 40px;
  }
  .career-position .no {
    font-size: 22px;
    line-height: 30px;
  }
  .career-description ul li,
  .career-description span,
  .career-description p {
    font-size: 18px;
    line-height: 24px;
  }
  .office-location {
    width: 1360px;
  }
  .office-location h3 {
    font-size: 46px;
    line-height: 55px;
    margin: 0 0 90px 0;
  }
  .switch {
    margin: 0 0 90px;
  }
  .switch p {
    font-size: 22px;
    line-height: 28px;
    padding: 18px 28px;
  }
  .address-location {
    gap: 40px;
  }
  .address-location h6 {
    font-size: 30px;
    line-height: 40px;
  }
  .address-item {
    gap: 35px;
  }
  .office-location .icon {
    width: 38px;
    height: 38px;
  }
  .address-location P {
    font-size: 22px;
    line-height: 28px;
  }
  .maps {
    height: 570px;
  }
}
@media (max-width: 1400px) {
  .main-text h1 {
    font-size: 45px;
    line-height: 60px;
  }
  .box-our-journey .container-inner {
    width: 1160px;
  }
  .box-journey {
    height: 900px;
  }
  .journey-title h3,
  .software-solution h3 {
    font-size: 40px;
    line-height: 52px;
  }
  .journey-desc .journey-date {
    font-size: 16px;
    line-height: 20px;
  }
  .box-journey h6 {
    font-size: 22px;
    line-height: 29px;
    height: 58px;
  }
  .journey-desc .journey-description p,
  .journey-desc .journey-description p span {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .journey-desc .card-inner {
    padding: 15px;
  }
  .journey-desc .card {
    padding: 25px;
  }
  .journey-desc {
    margin: 338px 0 200px 0;
  }
  .paginate-list {
    padding: 338px 0 200px 0;
  }
  .paginate-list::before {
    left: -35%;
  }
  .btn-year-list::before {
    left: -41%;
  }
  .btn-year-list {
    font-size: 16px;
    line-height: 21px;
  }
  .clients-box {
    padding: 120px 0px;
  }
  .client-testimony {
    width: 1200px;
  }
  .clients-box .client-title {
    font-size: 28px;
    line-height: 36px;
  }
  .clients-box .sub-title {
    font-size: 20px;
    line-height: 28px;
  }
  .clients-box h4 {
    font-size: 32px;
    line-height: 34px;
  }
  .clients-box h3 {
    font-size: 40px;
    line-height: 52px;
    margin: 70px 0 77px 0;
  }
  .client-card {
    height: 612px;
  }
  .client-list {
    margin: 160px 0;
  }
  .img-client-brand {
    height: 50px;
    margin: 0 0 15px 0;
  }
  .client-desc .s-4052 {
    font-size: 36px;
    line-height: 46px;
  }
  .client-desc.ms-40 {
    margin-left: 30px;
  }
  .client-desc p {
    font-size: 18px;
    line-height: 24px;
  }
  .software-option .card-outer {
    padding: 20px;
  }
  .software-option {
    width: 1160px;
    margin: 50px 0 120px 0;
  }
  .software-option .card {
    padding: 30px 30px;
    height: 405px;
  }
  .technology-stack {
    padding: 160px 0;
  }
  .technology-stack h6 {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 80px 0;
  }
  .box-articles {
    margin: 80px 0 160px 0;
  }
  .contant-article {
    width: 1160px;
  }
  .article-short {
    width: 22%;
    padding: 0 20px 0 0;
  }
  .article-list {
    width: 78%;
  }
  .article-short h4 {
    font-size: 44px;
    line-height: 56px;
  }
  .article-short .description {
    font-size: 16px;
    line-height: 21px;
  }
  .box-link-view {
    margin: 30px 0 0 0;
  }
  .article-short .no {
    font-size: 16px;
    line-height: 20px;
  }
  .card-article {
    padding: 14px;
  }
  .news-box img {
    height: 230px;
    border-radius: 10px 10px 0px 0px;
  }
  .news-box p {
    font-size: 16px;
    line-height: 18px;
  }
  .articles .text-box {
    height: 275px;
  }
  .text-box h3 {
    font-size: 16px;
    line-height: 20px;
    margin: 24px 0 16px 0px;
    height: 40px;
  }
  .text-box .description {
    font-size: 15px;
    line-height: 19px;
  }
  .text-box .no {
    font-size: 16px;
    line-height: 21px;
  }
  .box-career {
    margin: 0 0 160px 0;
  }
  .box-career .container-inner {
    padding-top: 90px;
    width: 1160px;
  }
  .box-career h3 {
    font-size: 42px;
    line-height: 48px;
    margin: 0 0 80px 0;
  }
  .box-career h5 {
    font-size: 28px;
    line-height: 36px;
    margin: 0px 0 40px 0;
  }
  .career-division {
    margin: 0 0 80px 0;
    gap: 30px;
  }
  .text-start.link a {
    height: 46px;
    font-size: 20px;
    line-height: 26px;
  }
  .career-position {
    gap: 30px;
  }
  .career-position .no {
    font-size: 20px;
    line-height: 26px;
  }
  .description-point {
    margin: 0 0 40px 0;
  }
  .career-description {
    padding: 0 0 0 25px;
  }
  .career-description ul li,
  .career-description span,
  .career-description p {
    font-size: 16px;
    line-height: 20px;
  }
  .box-career button {
    font-size: 16px;
    line-height: 20px;
  }
  .box-office-location {
    margin: 0 0 160px 0;
  }
  .office-location {
    width: 1160px;
  }
  .office-location h3 {
    font-size: 44px;
    line-height: 50px;
    margin: 0 0 80px 0;
  }
  .switch {
    margin: 0 0 80px;
  }
  .switch p {
    font-size: 20px;
    line-height: 26px;
  }
  .address-location {
    gap: 30px;
  }
  .address-location h6 {
    font-size: 28px;
    line-height: 38px;
  }
  .address-item {
    gap: 30px;
  }
  .office-location .icon {
    width: 36px;
    height: 36px;
  }
  .address-location P {
    font-size: 20px;
    line-height: 26px;
  }
  .maps {
    height: 550px;
  }
}
@media (max-width: 1200px) {
  .main-text h1 {
    font-size: 42px;
    line-height: 55px;
  }
  .box-our-journey .container-inner {
    width: 985px;
  }
  .box-journey {
    height: 850px;
  }
  .journey-title h3,
  .software-solution h3 {
    font-size: 34px;
    line-height: 44px;
  }
  .journey-desc .card {
    padding: 20px;
  }
  .journey-desc {
    margin: 308px 0 170px 0;
  }
  .paginate-list {
    padding: 308px 0 170px 0;
  }
  .btn-year-list {
    width: 85px;
    height: 40px;
  }
  .clients-box {
    padding: 100px 0px;
  }
  .client-testimony {
    width: 100%;
  }
  .client-testimony .card {
    padding: 20px;
    margin-bottom: 50px;
    height: 265px;
  }
  .clients-box .client-title {
    font-size: 24px;
    line-height: 32px;
  }
  .clients-box .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0;
  }
  .client-testimony .testimony-desc p {
    font-size: 14px;
    line-height: 18px;
  }
  .clients-box h4 {
    font-size: 30px;
    line-height: 32px;
  }
  .clients-box h3 {
    font-size: 34px;
    line-height: 44px;
    margin: 60px 0 67px 0;
  }
  .client-card {
    height: 512px;
  }
  .client-list {
    margin: 140px 0;
  }
  .client-desc {
    padding: 20px;
    height: 310px;
  }
  .client-desc.ms-40 {
    margin-left: 20px;
  }
  .client-desc .mt-40 {
    margin-top: 25px;
  }
  .client-desc .s-4052 {
    font-size: 32px;
    line-height: 46px;
  }
  .client-desc p {
    font-size: 16px;
    line-height: 22px;
  }
  .software-option {
    width: 985px;
    margin: 50px 0 100px 0;
  }
  .software-option .card-outer {
    padding: 15px;
  }
  .technology-stack {
    padding: 140px 0;
  }
  .technology-stack h6 {
    font-size: 28px;
    line-height: 38px;
    margin: 0 0 70px 0;
  }
  .box-articles {
    margin: 70px 0 140px 0;
  }
  .contant-article {
    width: 985px;
  }
  .article-short {
    width: 27%;
    padding: 0 20px 0 0;
  }
  .article-list {
    width: 73%;
  }
  .article-short h4 {
    font-size: 38px;
    line-height: 48px;
  }
  .box-office-location {
    margin: 0 0 140px 0;
  }
  .box-career {
    margin: 0 0 140px 0;
  }
  .box-career .container-inner {
    padding-top: 80px;
    width: 985px;
  }
  .box-career h3 {
    font-size: 38px;
    line-height: 44px;
    margin: 0 0 70px 0;
  }
  .box-career h5 {
    font-size: 26px;
    line-height: 34px;
    margin: 0px 0 35px 0;
  }
  .career-division {
    margin: 0 0 70px 0;
    gap: 25px;
  }
  .text-start.link a {
    height: 44px;
    font-size: 18px;
    line-height: 24px;
  }
  .career-position {
    width: 25%;
    gap: 25px;
  }
  .career-position .no {
    font-size: 18px;
    line-height: 22px;
  }
  .description-point {
    margin: 0 0 30px 0;
  }
  .career-description {
    width: 75%;
    padding: 0 0 0 20px;
  }
  .career-description ul li,
  .career-description span,
  .career-description p {
    font-size: 16px;
    line-height: 20px;
  }
  .box-career button {
    padding: 12px 16px;
    font-size: 16px;
    line-height: 20px;
  }
  .office-location {
    width: 985px;
  }
  .office-location h3 {
    font-size: 38px;
    line-height: 44px;
    margin: 0 0 70px 0;
  }
  .switch {
    margin: 0 0 70px;
  }
  .switch p {
    font-size: 18px;
    line-height: 24px;
  }
  .address-location {
    gap: 25px;
  }
  .address-location h6 {
    font-size: 26px;
    line-height: 36px;
  }
  .address-item {
    gap: 25px;
  }
  .office-location .icon {
    width: 34px;
    height: 34px;
  }
  .address-location P {
    font-size: 18px;
    line-height: 24px;
  }
  .maps {
    height: 500px;
  }
}
@media (max-width: 1024px) {
  .main-text h1 {
    font-size: 38px;
    line-height: 48px;
  }
  .box-our-journey .container-inner {
    width: 952px;
  }
  .journey-title h3,
  .software-solution h3 {
    font-size: 30px;
    line-height: 38px;
  }
  .journey-desc .card-inner {
    padding: 10px;
  }
  .btn-year-list {
    width: 80px;
    height: 35px;
    font-size: 14px;
    line-height: 18px;
  }
  .btn-year-list::before {
    width: 12px;
    height: 12px;
  }
  .box-articles .slick-prev {
    left: 80%;
  }
  .client-inner {
    padding: 0;
  }
  .clients-box .slick-dots li,
  .clients-box .slick-dots li.slick-active button {
    width: 20px;
    height: 20px;
  }
  .clients-box h4 {
    font-size: 26px;
    line-height: 30px;
  }
  .clients-box h3 {
    font-size: 30px;
    line-height: 38px;
    margin: 50px 0 57px 0;
  }
  .clients-box .client-title {
    font-size: 20px;
    line-height: 26px;
  }
  .client-card {
    height: 412px;
  }
  .client-list {
    margin: 150px 0;
  }
  .client-desc p {
    font-size: 14px;
    line-height: 20px;
  }
  .client-desc {
    height: 250px;
  }
  .client-desc .s-4052 {
    font-size: 24px;
    line-height: 38px;
  }
  .software-option {
    width: 100%;
    margin: 50px 0 90px 0;
  }
  .software-option .card {
    height: 300px;
    padding: 20px;
  }
  .software-option img {
    max-width: 200px;
  }
  .software-option .slick-list {
    padding: 0 40px 0 0 !important;
  }
  .technology-stack {
    padding: 120px 0;
  }
  .technology-stack h6 {
    font-size: 26px;
    line-height: 36px;
    margin: 0 0 60px 0;
  }
  .box-articles {
    margin: 60px 0 120px 0;
  }
  .contant-article {
    width: 952px;
  }
  .article-short {
    width: 30%;
    padding: 0 16px 0 0;
  }
  .article-list {
    width: 70%;
  }
  .article-short h4 {
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 30px;
  }
  .article-short .no {
    font-size: 14px;
    line-height: 18px;
  }
  .news-box img {
    height: 220px;
    border-radius: 10px 10px 0px 0px;
  }
  .news-box p {
    font-size: 14px;
    line-height: 16px;
    padding: 5px 10px;
  }
  .articles .text-box {
    height: 250px;
  }
  .box-office-location {
    margin: 0 0 120px 0;
  }
  .box-career {
    margin: 0 0 120px 0;
  }
  .box-career .container-inner {
    padding-top: 70px;
    width: 952px;
  }
  .box-career h3 {
    font-size: 34px;
    line-height: 38px;
    margin: 0 0 60px 0;
  }
  .box-career h5 {
    font-size: 24px;
    line-height: 32px;
    margin: 0px 0 30px 0;
  }
  .career-division {
    margin: 0 0 60px 0;
    gap: 20px;
  }
  .text-start.link a {
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
  .career-position {
    width: 28%;
    gap: 20px;
  }
  .career-position .no {
    font-size: 18px;
    line-height: 22px;
  }
  .description-point {
    margin: 0 0 30px 0;
  }
  .career-description {
    width: 72%;
    padding: 0 0 0 20px;
  }
  .career-description ul li,
  .career-description span,
  .career-description p {
    font-size: 14px;
    line-height: 18px;
  }
  .box-career button {
    padding: 10px 14px;
    font-size: 14px;
    line-height: 18px;
  }
  .office-location {
    width: 952px;
  }
  .office-location h3 {
    font-size: 34px;
    line-height: 38px;
    margin: 0 0 60px 0;
  }
  .switch {
    margin: 0 0 60px;
  }
  .switch p {
    font-size: 16px;
    line-height: 22px;
    padding: 16px 24px;
  }
  .address-location {
    gap: 20px;
  }
  .address-location h6 {
    font-size: 24px;
    line-height: 34px;
  }
  .address-item {
    gap: 20px;
  }
  .office-location .icon {
    width: 32px;
    height: 32px;
  }
  .address-location P {
    font-size: 16px;
    line-height: 20px;
  }
  .maps {
    height: 450px;
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 991px) {
  /* style our journey start */
  .main-text h1 {
    font-size: 32px;
    line-height: 38px;
  }
  .box-our-journey .container-inner {
    width: calc(100% - 28px);
  }
  .box-journey {
    flex-direction: row-reverse;
    height: 600px;
  }
  .box-our-journey .bg-journey {
    display: none;
  }
  .journey-desc {
    width: 83%;
    margin: 140px 0 70px 0;
  }
  .journey-desc .card {
    border-radius: 10px;
    height: 286px;
  }
  .paginate-list {
    z-index: 2;
    padding: 140px 0 70px 0;
  }
  .journey-desc .card-inner {
    padding: 7px;
  }
  .journey-desc .slick-list {
    padding: 0 60px 0 0 !important;
  }
  .journey-title {
    height: 36px;
    margin: 100px 0 50px 0;
  }
  .journey-title h3,
  .software-solution h3 {
    font-size: 28px;
    line-height: 34px;
    color: #000;
  }
  .paginate-list::before {
    content: none;
  }
  .paginate-list .times {
    position: relative;
    gap: 20px;
  }
  .paginate-list .times::before {
    content: "";
    position: absolute;
    top: 8px;
    width: 2px;
    height: calc(100% - 18px);
    background-color: #b89848;
  }
  .journey-paginate {
    width: 17%;
    margin-left: 0;
    justify-content: center;
    padding: 0 15px;
  }
  .times img {
    display: none;
  }
  .btn-year-list {
    width: 60px;
    height: 28px;
    color: #6f6f6f;
    background-color: #e8e8e8;
    border-color: transparent;
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .btn-year-list.active {
    background-color: #b89848;
    border-color: #b89848;
    color: #fff;
  }
  .btn-year-list::before {
    content: none;
  }
  /* style our journey end */
  .client-case-study {
    width: 100%;
  }

  .client-testimony .slick-list {
    padding: 0 30px 0 0 !important;
  }
  .clients-box .slick-dots li,
  .clients-box .slick-dots li.slick-active button {
    width: 20px;
    height: 20px;
  }
  .clients-box h4 {
    font-size: 22px;
    line-height: 26px;
  }
  .clients-box h3 {
    font-size: 28px;
    line-height: 34px;
    margin: 40px 0 47px 0;
  }
  .client-card .client-inner {
    flex-wrap: wrap;
  }
  .clients-box .client-title {
    font-size: 18px;
    line-height: 24px;
  }
  .clients-box .sub-title {
    margin: 12px 0;
  }
  .client-card .img-client-bg {
    position: relative;
    height: 450px;
    border-radius: 10px 10px 0px 0px;
  }
  .client-card {
    height: max-content;
    border-radius: 10px;
    margin: 0 0 30px 0;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .client-content {
    justify-content: end;
  }
  .client-desc {
    display: none;
  }
  .client-desc-mobile {
    display: block;
    height: 216px;
    padding: 20px;
  }
  .client-desc-mobile h6 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 15px 0;
  }
  .client-desc-mobile p {
    font-size: 16px;
    line-height: 18px;
  }
  .img-client-brand {
    height: 35px;
  }
  .client-desc-mobile .mt-40 {
    margin-top: 20px;
  }
  .client-ss {
    width: 40%;
    margin: 0;
  }
  .client-case-study .slick-dots {
    top: unset;
    right: 0%;
    bottom: -30px;
    height: unset;
    width: 100%;
    transform: rotate(0deg);
  }
  .clients-box .slick-dots li button {
    width: 10px;
    height: 10px;
  }
  .clients-box .slick-dots li.slick-active button {
    width: 14px;
    height: 14px;
  }
  /* style client says start */
  .client-testimony {
    width: 100%;
  }
  .clients-box {
    padding: 50px 0px;
  }
  .box-our-journey {
    height: auto;
  }
  .client-testimony .slick-current {
    transform: none;
    transition: 0.3s;
  }
  .client-testimony .p-3 {
    padding: 10px !important;
  }
  .client-list {
    margin: 120px 0;
  }
  .client-testimony .card {
    height: 260px;
    margin-bottom: 0;
    border-radius: 10px;
  }
  /* style client says start */
  /* software solution start */
  .software-option {
    width: 100%;
    margin: 50px 0 80px 0;
  }

  .software-option .card-outer {
    padding: 10px;
  }
  .software-option .box-ss {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: start;
  }
  .software-option .box-ss .ss-title {
    margin: 0 0 0 15px;
  }
  .software-option .card {
    border-radius: 10px;
  }
  .ss-desc-desktop {
    display: none;
  }
  .ss-desc-mobile {
    display: block !important;
  }
  .ss-desc-mobile a {
    color: #000 !important;
    font-size: 14px;
    line-height: 18px;
  }
  .box-ss .width-100 {
    width: fit-content;
  }
  .box-ss .ss-image img {
    width: 97px;
  }
  .software-solution .s-2431 {
    font-size: 16px;
    line-height: 20px;
  }
  .uss-bg-top,
  .uss-bg-bottom {
    width: 50%;
  }
  .software-utilize {
    height: 700px;
  }
  .technology-stack {
    padding: 100px 0;
  }
  .technology-stack h6 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 50px 0;
  }
  /* software solution end */
  /* style article start */
  .box-articles {
    margin: 50px 0 100px 0;
  }
  .contant-article {
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0;
  }
  .article-short {
    width: 100%;
    padding: 0 20px;
  }
  .article-short h4 {
    font-family: "Arlon", sans-serif;
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 26px;
  }
  .article-list {
    width: 100%;
  }
  .box-link-view {
    position: absolute;
    top: 8%;
    right: 20px;
    margin: 0;
  }
  .box-articles .container-inner {
    width: 100%;
  }
  .box-articles .width-30 {
    position: relative;
    padding: 14px;
  }
  .box-articles .width-30 .mt-40 {
    position: absolute;
    right: 24px;
    top: 0;
    margin-top: 14px;
  }
  .box-articles .width-30,
  .box-articles .width-70 {
    width: 100%;
  }
  .box-articles .slick-list {
    padding: 0 30px 0 0 !important;
  }
  .box-articles .card-article {
    padding: 10px;
  }
  /* style article end */

  /* style Career Start */
  .career-position {
    width: 100%;
    flex-direction: row;
    gap: 16px;
    overflow-x: scroll;
    padding: 0px 0 15px 0;
    margin: 0 0 20px 0;
  }
  .career-position .no {
    font-family: "DMSans-400", sans-serif;
    font-size: 16px;
    line-height: 18px;
  }
  .career-position .c-89 {
    font-family: "DMSans-700", sans-serif;
    color: #fff !important;
    background-color: #b89848;
    border-color: #b89848;
  }
  /* style Career Start */

  .box-career .container-inner {
    padding-top: 60px;
    width: calc(100% - 28px);
  }
  .box-career h3 {
    font-size: 30px;
    line-height: 34px;
    margin: 0 0 50px 0;
  }
  .box-career h5 {
    display: none;
  }
  .career-division {
    overflow-x: scroll;
    margin: 0 0 40px 0;
  }

  .career-position a {
    padding: 6px 12px;
    border-radius: 40px;
    background-color: #f8f8f8;
    border-color: #f8f8f8;
    transition: 0.3s;
  }
  .career-description {
    width: 100%;
    padding: 0;
    border-left: 0px;
    justify-content: center !important;
  }
  .box-career button {
    padding: 12px 20px;
  }
  .box-office-location {
    margin: 0 0 100px 0;
  }
  .office-location h3 {
    font-size: 30px;
    line-height: 34px;
    margin: 0 0 50px 0;
  }
  .address-list {
    padding: 0 20px;
  }
  .switch {
    margin: 0 0 50px;
    overflow-x: scroll;
  }
  .switch p {
    padding: 12px 35px;
    transition: 0.3s;
  }
  .office-location {
    width: 100%;
  }
  .office-location .row {
    justify-content: center;
  }
  .office-location .row > * {
    padding-right: 0;
  }
  .address-location {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 0 20px 0;
    gap: 16px;
  }
  .office-location h6 {
    font-size: 16px;
    line-height: 21px;
  }
  .office-location .icon {
    width: 24px;
    height: 24px;
  }
  .maps {
    width: 100%;
    padding: 0 !important;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (max-width: 767px) {
  .main-text h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .journey-title h3,
  .software-solution h3 {
    font-size: 26px;
    line-height: 32px;
  }
  .img-client-brand {
    height: 30px;
    margin: 0 0 25px 0;
  }
  .clients-box h4 {
    font-size: 20px;
    line-height: 24px;
  }
  .clients-box h3 {
    font-size: 26px;
    line-height: 32px;
    margin: 30px 0 37px 0;
  }
  .client-desc-mobile .mt-40 {
    margin-top: 25px;
  }
  .client-inner {
    min-height: 200px;
  }
  .client-testimony .testimony-desc p {
    font-size: 14px;
    line-height: 18px;
  }
  .box-articles {
    margin: 50px 0 90px 0;
  }
  .news-box img {
    height: 200px;
  }
  .articles .text-box {
    padding: 20px 14px;
    height: 200px;
  }
  .journey-desc .slick-list {
    padding: 0 50px 0 0 !important;
  }
  .client-card .img-client-bg {
    height: 350px;
  }
  .client-desc-mobile {
    height: 230px;
    padding: 14px 20px;
  }
  .client-desc-mobile h6 {
    font-family: "DMSans-700", sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 16px 0;
  }
  .clients-box .sub-title {
    margin: 10px 0;
  }
  .client-desc-mobile p {
    font-family: "DMSans-400", sans-serif;
    font-size: 18px;
    line-height: 22px;
  }
  .client-list {
    margin: 100px 0;
  }
  .software-option {
    margin: 50px 0 70px 0;
  }
  .software-option .slick-list {
    padding: 0 30px 0 0 !important;
  }
  .technology-stack h6 {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 50px 0;
  }
  .article-short {
    padding: 0 14px;
  }
  .text-box h3 {
    font-size: 16px;
    line-height: 18px;
    margin: 10px 0 5px 0;
    height: 20px;
  }
  .news-box p {
    top: 8px;
    left: 12px;
    padding: 5px 10px;
  }
  .article-short h4 {
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 24px;
  }
  .box-link-view {
    top: 5%;
  }
  .article-short .description {
    font-size: 14px;
    line-height: 18px;
  }
  .text-box .description {
    font-size: 14px;
    line-height: 18px;
  }
  .text-box .no {
    font-size: 14px;
    line-height: 18px;
    margin: 20px 0 0;
  }
  .box-career .container-inner {
    padding-top: 50px;
  }
  .box-career h3 {
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 40px 0;
  }
  .career-division {
    margin: 0 0 30px 0;
    gap: 16px;
  }
  .text-start.link a {
    height: 36px;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 15px 0;
  }
  .career-position .no {
    font-size: 14px;
    line-height: 16px;
  }
  .box-office-location {
    margin: 0 0 80px 0;
  }
  .office-location h3 {
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 40px 0;
  }
  .switch {
    margin: 0 0 40px;
  }
  .switch p {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 14px;
  }
  .address-item {
    gap: 16px;
  }
  .address-location {
    gap: 14px;
  }
  .address-location P {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 568px) {
  .main-text h1 {
    font-size: 26px;
    line-height: 34px;
  }
  .box-journey {
    height: 465px;
  }
  .journey-title {
    height: 26px;
    margin: 50px 0 30px 0;
  }
  .journey-title h3,
  .software-solution h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .journey-desc {
    width: 80%;
    margin: 106px 0 70px 0;
  }
  .paginate-list {
    padding: 106px 0 70px 0;
  }
  .journey-paginate {
    width: 20%;
  }
  .journey-desc .slick-list {
    padding: 0 40px 0 0 !important;
  }
  .clients-box h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .software-option {
    margin: 50px 0 60px 0;
  }
  .clients-box h3 {
    font-size: 22px;
    line-height: 28px;
    margin: 25px 0 32px 0;
  }
  .client-card .img-client-bg {
    height: 250px;
  }
  .client-desc-mobile p,
  .client-desc-mobile span,
  .client-desc-mobile b {
    font-size: 16px;
    line-height: 20px;
  }
  .client-desc-mobile h6 {
    font-size: 18px;
    line-height: 22px;
    margin: 0px 0 10px 0;
  }
  .client-list {
    margin: 70px 0;
  }
  .technology-stack h6 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 50px 0;
  }
  .box-articles {
    margin: 50px 0 80px 0;
  }
  .article-short h4 {
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 22px;
  }
  .box-link-view {
    top: 2%;
  }
  .news-box img {
    height: 170px;
  }
  .articles .text-box {
    height: 210px;
  }
  .text-box h3 {
    height: 40px;
  }
  .box-career h3 {
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 30px 0;
  }
  .career-division {
    margin: 0 0 20px 0;
    gap: 14px;
  }
  .box-office-location {
    margin: 0;
  }
  .office-location h3 {
    font-size: 24px;
    line-height: 26px;
    margin: 0 0 30px 0;
  }
  .switch {
    margin: 0px 0 30px;
  }
  .maps {
    height: 400px;
  }
}

@media (max-width: 414px) {
  .main-text h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .software-option {
    margin: 50px 0 50px 0;
  }
  .journey-title h3,
  .software-option h3,
  .clients-box h3 {
    font-size: 20px;
    line-height: 26px;
  }
  .clients-box h4 {
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 27px 0;
  }
  .homepage .clients-box .bg-slide {
    height: 196px;
  }
  .clients-box .sub-title {
    font-size: 15px;
  }
  .img-client-brand {
    margin: 0 0 10px 0;
  }
  .client-card .img-client-bg {
    height: 196px;
  }
  .client-desc-mobile {
    height: 210px;
  }
  .client-desc-mobile h6 {
    font-size: 16px;
    line-height: 20px;
    margin: 0px 0 10px 0;
  }
  .client-desc-mobile p,
  .client-desc-mobile span,
  .client-desc-mobile b {
    font-size: 14px !important;
    line-height: 18px;
  }
  .client-desc-mobile .mt-40 {
    margin-top: 18px;
  }
  .client-list {
    margin: 50px 0;
  }
  .technology-stack {
    padding: 80px 0 70px;
  }
  /* Style Articles start */
  .box-articles {
    margin: 50px 0 60px 0;
  }
  .article-short h4 {
    font-size: 20px;
    line-height: 26px;
    margin: 0px 0 20px;
  }
  .articles .text-box {
    height: 227px;
  }
  .box-link-view {
    top: 1%;
  }
  .news-box img {
    height: 146px;
  }
  /* Style Articles end */
  /* style Career start */
  .box-career h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 20px 0;
  }
  /* style Career end */
  /* OFFICE LOCATION START  */
  .office-location h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 20px 0;
  }
  .switch {
    margin: 0px 0 20px;
  }
  .address-item {
    gap: 14px;
  }
  .homepage .maps {
    height: 275px;
  }
  /* OFFICE LOCATION END  */
}
