.top-article .banner,
.top-article .banner-text,
.top-article .list-top-articles {
  position: relative;
  height: 489px;
}
.top-article .banner-text {
  padding: 25px 10px 25px 28px;
}
.top-article .img-top-article {
  position: absolute;
  left: 18px;
  top: 0;
  float: left;
  width: calc(100% - 18px);
  height: 100%;
  border-radius: 20px;
}
.top-article a p{
  color: #000 !important;
}
.top-article .list-top-articles {
  overflow-y: scroll;
}
/* .top-article .nav-list {
  top: 0;
  position: sticky;
} */
.box-img-article {
  height: 253px;
}
.box-img-article p {
  width: fit-content;
  height: 43px;
  left: 14px;
  top: 14px;
}
.box-text-article {
  height: 316px;
}
.list-article .img-article {
  position: absolute;
  height: 100%;
  left: 0;
  border-radius: 10px 10px 0px 0px;
}
.nav-list .btn-primary{
  font-family: 'DMSans-400';
  font-size: 24px;
  line-height: 31px;
}
.nav-list .btn-primary {
  --bs-btn-color: #6F6F6F;
  --bs-btn-bg: #F3F3F3;
  --bs-btn-border-color: #F3F3F3;

  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #B89848;
  --bs-btn-hover-border-color: #B89848;

  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #B89848;
  --bs-btn-active-border-color: #B89848;
  
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #F3F3F3;
  --bs-btn-disabled-border-color: #F3F3F3;
}
.scroll-article .content {
  padding: 10px 10px 10px 21px;
  box-sizing: border-box;
  width: calc(100% - 126px);
}
.scroll-article .img-top-list {
  width: 126px;
  height: 80px;
}

.scroll-article .list-p p{
  font-family: 'DMSans-400';
  font-size: 18px;
  line-height: 23px;
  text-align: start;
}
.border-box {
  box-sizing: border-box;
}
.list-top-articles {
  padding: 0 14px 0 50px;
}
.article-inner {
  padding: 20px;
}
.article-item .image {
  box-sizing: border-box;
  background-size: cover;
  border-radius: 10px 10px 0px 0px;
}
@media (max-width: 991px) {
  .articles .my-200 {
    margin: 100px 0 50px 0;
  }
  .top-article .banner {
    margin: 0 0 50px 0;
  }
  .top-article .list-top-articles {
    overflow-y: unset;
    height: auto;
    padding: 0 14px;
  }


  .articles .s-4862 {
    font-size: 20px;
    line-height: 26px;
  }
  .articles .s-3242 {
    font-size: 16px;
    line-height: 20px;
  }
  .banner-text .s-1823,
  .banner-text .s-14 {
    padding: 10px;
    line-height: 8px;
    height: 28px;
  }
}

@media (max-width: 767px) {
}
@media (max-width: 575px) {
  .list-article .article-item {
    padding: 16px;
  }
  .box-img-article {
    width: 40%;
    height: 132px;
  }
  .box-text-article {
    width: 60%;
    height: auto;
    padding: 0 0 0 20px;
  }
  .box-text-article .c-89 {
    text-align: end;
  }
  .list-article .img-article {
    border-radius: 10px;
  }
}
